import React from 'react';
import Layout from "../../components/calculator/layout";
import SEO from "../../components/seo";

import Sidebar from "../../components/calculator/sidebar";
import Step5 from "../../components/calculator/step-5";

const CalculatorStep5 = (props) => (
    <Layout>
        <SEO title="Calculator Step 5"/>
        <div id="wrapper" class="d-flex justify-content-between">
                  <Sidebar {...props}/> 
            <Step5/>
        </div>
        
    </Layout>
)

export default CalculatorStep5;

